




































































































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.range-date::v-deep {
  .v-btn {
    width: 100%;
  }
}

.br-btn-cancelar.isActive {
  background-color: $colorSecondary !important;
  color: #FFF !important;
  border-color: $colorSecondary !important;
}
